<template>
  <ResetPassword/>
</template>

<script>
import { defineComponent } from 'vue';
import ResetPassword from '@/components/Login/ResetPassword.vue';

export default defineComponent({
  name: 'ResetPasswordView',
  components: { ResetPassword },
});
</script>

<style>
</style>
